<style lang="scss">
  @import "@/assets/scss/global";
</style>

<script setup>
import SplashScreen from '@/components/SplashScreen.vue';
import { onMounted, ref } from 'vue';
import InfoBox from '@/components/InfoBox.vue';

const isLoading = ref(true);

onMounted(() => {
  setTimeout(() => {
    hideSplash()
  }, 2000)
})

const hideSplash = () => {
  isLoading.value = false;
};
</script>

<template>
  <SplashScreen
    v-if="isLoading"
    @hideSplash="hideSplash"
  />
  <InfoBox
    v-if="!isLoading"
  />
</template>
