<style lang="scss">
  @import "@/assets/scss/splash-screen";
</style>

<script setup>
const emits = defineEmits(['hideSplash'])
const onSplashClick = () => {
   emits('hideSplash');
}

</script>

<template>
  <section
    class="splash-screen"
    @click.stop="onSplashClick"
  >
    <blockquote class="splash-screen__title">
      <ins class="splash-screen__char">Y</ins>
      <ins class="splash-screen__char">O</ins>
      <ins class="splash-screen__char">S</ins>
    </blockquote>
  </section>
</template>
