<style lang="scss">
  @import "@/assets/scss/tonscan-logo";
</style>

<script setup>

</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25.9 25.9"
    class="tonscan-logo"
  >
    <path
      class="tonscan-logo__stroke"
      d="M23.8,1.1c0.5,0.3,0.7,1.7,0.5,4.9 c-0.1,1.1-0.2,2.3-0.5,3.4c1.1,1.8,1.3,3.9,0.9,6.3c-0.4,2.1-1.8,4-4,5.9c-2.2,1.8-4.8,3.5-7.8,3.5c-3,0-6.1-1.7-8-3.5 C3,19.7,1.9,18,1.3,16.1C0.6,13.9,0.9,11.6,2,9.3C1.7,8.1,1.6,6.9,1.5,5.9C1.3,2.2,1.5,1.5,2,1.1c0.7-0.5,3,0.8,6.8,4.2 c1.7-0.3,3.1-0.5,4.2-0.5s2.4,0.2,4.1,0.5C20.9,2,23.2,0.6,23.8,1.1L23.8,1.1z"
    />
    <path
      class="tonscan-logo__eyes"
      d="M12.9,19.6c-0.9,0-2.2-1.2-2.2-1.7c0-0.5,1.5-0.7,2.3-0.7c0.8,0,2.2,0.1,2.2,0.7 C15.2,18.4,13.8,19.5,12.9,19.6z M6.9,14.7c1.3,0.5,2.7,0.2,3-0.6c0.3-0.9-0.5-2-1.8-2.5C6.8,11,5.6,11,5.3,11.8 C5,12.7,5.6,14.2,6.9,14.7z M18.9,14.5c-1.3,0.6-2.6,0.4-3-0.5c-0.3-0.9,0.5-2,1.8-2.5c1.3-0.5,2.3-0.6,2.7,0.3 C20.8,12.7,20.2,13.9,18.9,14.5z"
    />
  </svg>
</template>
