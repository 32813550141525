<style lang="scss">
  @import "@/assets/scss/ton-logo";
</style>

<script setup>

</script>

<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 147 56"
    class="ton-logo"
  >
<g>
	<defs>
		<rect id="SVGID_1_" width="147" height="56"/>
	</defs>
  <clipPath id="SVGID_00000089561514057395217060000011787393884856468903_">
		<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
	</clipPath>
  <g style="clip-path:url(#SVGID_00000089561514057395217060000011787393884856468903_);">
		<path style="fill:#0098EA;" d="M28.2,56c15.5,0,28-12.5,28-28S43.6,0,28.2,0s-28,12.5-28,28S12.7,56,28.2,56z"/>
    <path style="fill:#FFFFFF;" d="M37.7,15.6H18.6c-3.5,0-5.7,3.8-4,6.9l11.8,20.5c0.8,1.3,2.7,1.3,3.5,0l11.8-20.5
			C43.5,19.4,41.2,15.6,37.7,15.6L37.7,15.6z M26.4,36.8l-2.6-5l-6.2-11.1c-0.4-0.7,0.1-1.6,1-1.6h7.8L26.4,36.8L26.4,36.8z
			 M38.7,20.7l-6.2,11.1l-2.6,5V19.1h7.8C38.6,19.1,39.1,20,38.7,20.7z"/>
    <path style="fill:#FFFFFF;" d="M73.8,43.5h6.3V21.6h8.7v-6H65.1v6h8.7V43.5z"/>
    <path style="fill:#FFFFFF;" d="M103.8,43.9c7.9,0,14.4-6.5,14.4-14.4c0-7.9-6.5-14.4-14.4-14.4c-8,0-14.4,6.5-14.4,14.4
			C89.4,37.5,95.9,43.9,103.8,43.9z M103.8,37.9c-4.5,0-8.1-3.8-8.1-8.3s3.6-8.3,8.1-8.3c4.5,0,8.1,3.8,8.1,8.3
			S108.3,37.9,103.8,37.9z"/>
    <path style="fill:#FFFFFF;" d="M146.8,43.5V15.6h-6.3v17.2L127,15.6h-5.4v27.8h6.2V26.3l13.6,17.2H146.8z"/>
	</g>
</g>
</svg>

</template>
