<style lang="scss">
  @import "@/assets/scss/stone-fi-logo";
</style>

<script setup>

</script>

<template>
  <svg
    viewBox="0 0 109 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stone-fi-logo"
  >
    <path d="M16.5962 21.8378C16.749 21.7318 16.9605 21.7998 17.0232 21.9753L19.3984 28.6205C19.4747 28.8342 19.2823 29.0467 19.0629 28.991L10.3178 26.7687C10.0826 26.709 10.0268 26.3993 10.2263 26.2608L16.5962 21.8378Z" fill="url(#paint0_linear_1258_10190)"></path>
    <path d="M18.1303 20.3711C18.1193 20.3404 18.1137 20.308 18.1137 20.2754V13.5782C18.1137 13.3887 17.932 13.2526 17.7509 13.3063L10.2574 15.5274C10.0762 15.5811 9.89459 15.445 9.89459 15.2555V10.9273C9.89459 10.8388 9.93577 10.7554 10.0059 10.7018L19.771 3.23786C19.9067 3.13413 20.1025 3.17374 20.1875 3.32214L26.0865 13.622C26.1282 13.695 26.1356 13.7828 26.1064 13.8617L21.1399 27.3128C21.0483 27.5609 20.6977 27.5591 20.6087 27.3099L18.1303 20.3711Z" fill="url(#paint1_linear_1258_10190)"></path>
    <path d="M7.39416 3.81836C7.44132 3.77204 7.50316 3.74373 7.56895 3.73835L16.5818 3.00106C16.8633 2.97802 17.0007 3.33776 16.7759 3.5093L9.09381 9.37091C9.02271 9.42516 8.93023 9.44251 8.84439 9.41769L3.78566 7.95509C3.57758 7.89493 3.51146 7.63205 3.66618 7.48008L7.39416 3.81836Z" fill="url(#paint2_linear_1258_10190)"></path>
    <path d="M2.37752 9.42683C2.39907 9.25389 2.5696 9.14121 2.73651 9.18962L8.0978 10.7444C8.21872 10.7795 8.30195 10.8905 8.30195 11.0168V17.3117C8.30195 17.5013 8.48375 17.6374 8.66491 17.5836L16.1065 15.3723C16.2876 15.3185 16.4694 15.4546 16.4694 15.6441V19.6426C16.4694 19.7354 16.4241 19.8224 16.3481 19.8754L8.00143 25.6944C7.89258 25.7703 7.74577 25.7602 7.64837 25.67L1.21495 19.7125C1.14794 19.6505 1.1149 19.5598 1.12621 19.469L2.37752 9.42683Z" fill="url(#paint3_linear_1258_10190)"></path>
    <path d="M40.52 23.64C39.4267 23.64 38.38 23.4867 37.38 23.18C36.38 22.86 35.5867 22.4533 35 21.96L35.9 19.94C36.46 20.38 37.1533 20.7467 37.98 21.04C38.8067 21.3333 39.6533 21.48 40.52 21.48C41.2533 21.48 41.8467 21.4 42.3 21.24C42.7533 21.08 43.0867 20.8667 43.3 20.6C43.5133 20.32 43.62 20.0067 43.62 19.66C43.62 19.2333 43.4667 18.8933 43.16 18.64C42.8533 18.3733 42.4533 18.1667 41.96 18.02C41.48 17.86 40.94 17.7133 40.34 17.58C39.7533 17.4467 39.16 17.2933 38.56 17.12C37.9733 16.9333 37.4333 16.7 36.94 16.42C36.46 16.1267 36.0667 15.74 35.76 15.26C35.4533 14.78 35.3 14.1667 35.3 13.42C35.3 12.66 35.5 11.9667 35.9 11.34C36.3133 10.7 36.9333 10.1933 37.76 9.82C38.6 9.43333 39.66 9.24 40.94 9.24C41.78 9.24 42.6133 9.34667 43.44 9.56C44.2667 9.77333 44.9867 10.08 45.6 10.48L44.78 12.5C44.1533 12.1267 43.5067 11.8533 42.84 11.68C42.1733 11.4933 41.5333 11.4 40.92 11.4C40.2 11.4 39.6133 11.4867 39.16 11.66C38.72 11.8333 38.3933 12.06 38.18 12.34C37.98 12.62 37.88 12.94 37.88 13.3C37.88 13.7267 38.0267 14.0733 38.32 14.34C38.6267 14.5933 39.02 14.7933 39.5 14.94C39.9933 15.0867 40.54 15.2333 41.14 15.38C41.74 15.5133 42.3333 15.6667 42.92 15.84C43.52 16.0133 44.06 16.24 44.54 16.52C45.0333 16.8 45.4267 17.18 45.72 17.66C46.0267 18.14 46.18 18.7467 46.18 19.48C46.18 20.2267 45.9733 20.92 45.56 21.56C45.16 22.1867 44.54 22.6933 43.7 23.08C42.86 23.4533 41.8 23.64 40.52 23.64Z" fill="currentColor"></path>
    <path d="M51.4028 23.44V11.64H46.7628V9.44H58.6428V11.64H54.0028V23.44H51.4028Z" fill="currentColor"></path>
    <path d="M66.9587 23.64C65.8654 23.64 64.8587 23.46 63.9387 23.1C63.0187 22.74 62.2188 22.24 61.5387 21.6C60.8587 20.9467 60.3321 20.1867 59.9587 19.32C59.5854 18.44 59.3987 17.48 59.3987 16.44C59.3987 15.4 59.5854 14.4467 59.9587 13.58C60.3321 12.7 60.8587 11.94 61.5387 11.3C62.2188 10.6467 63.0187 10.14 63.9387 9.78C64.8587 9.42 65.8588 9.24 66.9388 9.24C68.0321 9.24 69.0321 9.42 69.9388 9.78C70.8587 10.14 71.6587 10.6467 72.3387 11.3C73.0187 11.94 73.5454 12.7 73.9188 13.58C74.2921 14.4467 74.4787 15.4 74.4787 16.44C74.4787 17.48 74.2921 18.44 73.9188 19.32C73.5454 20.2 73.0187 20.96 72.3387 21.6C71.6587 22.24 70.8587 22.74 69.9388 23.1C69.0321 23.46 68.0387 23.64 66.9587 23.64ZM66.9388 21.36C67.6454 21.36 68.2988 21.24 68.8988 21C69.4987 20.76 70.0187 20.42 70.4587 19.98C70.8988 19.5267 71.2388 19.0067 71.4787 18.42C71.7321 17.82 71.8587 17.16 71.8587 16.44C71.8587 15.72 71.7321 15.0667 71.4787 14.48C71.2388 13.88 70.8988 13.36 70.4587 12.92C70.0187 12.4667 69.4987 12.12 68.8988 11.88C68.2988 11.64 67.6454 11.52 66.9388 11.52C66.2321 11.52 65.5788 11.64 64.9788 11.88C64.3921 12.12 63.8721 12.4667 63.4187 12.92C62.9787 13.36 62.6321 13.88 62.3787 14.48C62.1388 15.0667 62.0187 15.72 62.0187 16.44C62.0187 17.1467 62.1388 17.8 62.3787 18.4C62.6321 19 62.9787 19.5267 63.4187 19.98C63.8587 20.42 64.3787 20.76 64.9788 21C65.5788 21.24 66.2321 21.36 66.9388 21.36Z" fill="currentColor"></path>
    <path d="M77.2347 23.44V9.44H79.3747L88.1547 20.22H87.0947V9.44H89.6747V23.44H87.5347L78.7547 12.66H79.8147V23.44H77.2347Z" fill="currentColor"></path>
    <path d="M93.9861 23.58C93.5328 23.58 93.1461 23.4267 92.8261 23.12C92.5061 22.8 92.3461 22.4 92.3461 21.92C92.3461 21.4133 92.5061 21.0133 92.8261 20.72C93.1461 20.4133 93.5328 20.26 93.9861 20.26C94.4394 20.26 94.8261 20.4133 95.1461 20.72C95.4661 21.0133 95.6261 21.4133 95.6261 21.92C95.6261 22.4 95.4661 22.8 95.1461 23.12C94.8261 23.4267 94.4394 23.58 93.9861 23.58Z" fill="currentColor"></path>
    <path d="M98.3113 23.44V12.16C98.3113 11.04 98.6379 10.1467 99.2913 9.48C99.9579 8.8 100.905 8.46 102.131 8.46C102.571 8.46 102.985 8.50667 103.371 8.6C103.771 8.69333 104.105 8.84 104.371 9.04L103.691 10.92C103.505 10.7733 103.291 10.6667 103.051 10.6C102.811 10.52 102.565 10.48 102.311 10.48C101.791 10.48 101.398 10.6267 101.131 10.92C100.865 11.2 100.731 11.6267 100.731 12.2V13.4L100.811 14.52V23.44H98.3113ZM96.5513 14.84V12.84H103.671V14.84H96.5513ZM105.431 23.44V12.76H107.931V23.44H105.431ZM106.691 11C106.225 11 105.838 10.8533 105.531 10.56C105.238 10.2667 105.091 9.91333 105.091 9.5C105.091 9.07333 105.238 8.72 105.531 8.44C105.838 8.14667 106.225 8 106.691 8C107.158 8 107.538 8.14 107.831 8.42C108.138 8.68667 108.291 9.02667 108.291 9.44C108.291 9.88 108.145 10.2533 107.851 10.56C107.558 10.8533 107.171 11 106.691 11Z" fill="currentColor"></path>
    <defs>
      <linearGradient id="paint0_linear_1258_10190" x1="20.9157" y1="7.875" x2="0.327215" y2="28.1926" gradientUnits="userSpaceOnUse">
        <stop offset="0.015625" stop-color="#3294FF"></stop>
        <stop offset="1" stop-color="#005BBE"></stop>
      </linearGradient>
      <linearGradient id="paint1_linear_1258_10190" x1="20.9157" y1="7.875" x2="0.327215" y2="28.1926" gradientUnits="userSpaceOnUse">
        <stop offset="0.015625" stop-color="#3294FF"></stop>
        <stop offset="1" stop-color="#005BBE"></stop>
      </linearGradient>
      <linearGradient id="paint2_linear_1258_10190" x1="20.9157" y1="7.875" x2="0.327215" y2="28.1926" gradientUnits="userSpaceOnUse">
        <stop offset="0.015625" stop-color="#3294FF"></stop>
        <stop offset="1" stop-color="#005BBE"></stop>
      </linearGradient>
      <linearGradient id="paint3_linear_1258_10190" x1="20.9157" y1="7.875" x2="0.327215" y2="28.1926" gradientUnits="userSpaceOnUse">
        <stop offset="0.015625" stop-color="#3294FF"></stop>
        <stop offset="1" stop-color="#005BBE"></stop>
      </linearGradient>
    </defs>
  </svg>
</template>
