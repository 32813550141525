<style lang="scss">
  @import "@/assets/scss/gecko-logo";
</style>

<script setup>

</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 28 28"
    class="gecko-logo"
  >
    <g>
      <g>
        <g>

          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="9.6988" y1="-1.0292" x2="29.3525" y2="18.984" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_1_);" d="M26.4,10.1l-1.5-1.5c-1,0.9-2.9,1.6-4,1.9c-0.1,0-0.1,0-0.2,0.1c-0.3,0.3-0.6,0.6-0.9,0.9
				l2.3,2.3l4.2,4.2l0.1-0.1C28.5,15.7,28.5,12.3,26.4,10.1z"/>

          <linearGradient id="SVGID_00000141432203928327329460000006595750586801783697_" gradientUnits="userSpaceOnUse" x1="13.2376" y1="2.9917" x2="29.9067" y2="19.2862" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_00000141432203928327329460000006595750586801783697_);" d="M26.4,10.1l-1.5-1.5
				c-1,0.9-2.9,1.6-4,1.9c-0.1,0-0.1,0-0.2,0.1c-0.3,0.3-0.6,0.6-0.9,0.9l2.3,2.3l4.2,4.2l0.1-0.1C28.5,15.7,28.5,12.3,26.4,10.1z"
          />

          <linearGradient id="SVGID_00000183945475007236622310000005617210166862964641_" gradientUnits="userSpaceOnUse" x1="-0.632" y1="9.116" x2="19.0217" y2="29.1292" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_00000183945475007236622310000005617210166862964641_);" d="M5.9,13.8l4.4-4.4
				c0.1-0.3,0.2-0.6,0.4-0.9c1.3-3,4.3-4,7.1-4c0.4-0.7,1.1-1.1,1.8-1.1l-1.7-1.7c-2.1-2.1-5.6-2.1-7.7,0L10,1.7L1.7,10l4.2,4.2
				C5.8,14.1,5.8,13.9,5.9,13.8z"/>

          <linearGradient id="SVGID_00000170991407411663896230000006478744799609546170_" gradientUnits="userSpaceOnUse" x1="-9.2946" y1="3.9091" x2="25.1672" y2="33.751" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_00000170991407411663896230000006478744799609546170_);" d="M5.9,13.8l4.4-4.4
				c0.1-0.3,0.2-0.6,0.4-0.9c1.3-3,4.3-4,7.1-4c0.4-0.7,1.1-1.1,1.8-1.1l-1.7-1.7c-2.1-2.1-5.6-2.1-7.7,0L10,1.7L1.7,10l4.2,4.2
				C5.8,14.1,5.8,13.9,5.9,13.8z"/>
          <path style="fill:#FFFFFF;" d="M21.1,4.8L21.1,4.8C21.1,4.8,21.1,4.8,21.1,4.8C21.1,4.8,21.1,4.8,21.1,4.8z"/>

          <linearGradient id="SVGID_00000102536183902397070470000006910365603818043552_" gradientUnits="userSpaceOnUse" x1="24.9122" y1="24.3443" x2="4.1851" y2="3.3676" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_00000102536183902397070470000006910365603818043552_);" d="M22.1,13.8c0.1,0.1,0.1,0.3,0,0.4
				l-3.9,3.9l-1,1l-2.6,2.6l-0.4,0.4c-0.1,0.1-0.3,0.1-0.4,0l0,0l-3.9-3.9l-2.6-2.6l-1.3-1.3L1.7,10l-0.1,0.1
				c-2.1,2.1-2.1,5.6,0,7.7l1,1l7.3,7.3l0.2,0.2c2.1,2.1,5.6,2.1,7.7,0l0.1-0.1l8.3-8.3L22.1,13.8z"/>
        </g>
      </g>
      <g>
        <g>
          <path style="fill:#1E1E1E;" d="M21.1,4.8C21.1,4.8,21.1,4.8,21.1,4.8L21.1,4.8c-0.2-0.3-0.4-0.6-0.8-0.8l0,0c0,0,0,0,0,0
				c0,0-0.1,0-0.1-0.1c0,0.2,0,0.3-0.1,0.5c0,0,0,0.1-0.1,0.1C19.7,5,19.3,5.2,18.8,5c-0.2-0.1-0.4-0.2-0.5-0.4
				c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.8,0,1.7,0.8,2.1c0.8,0.3,1.7,0,2.1-0.8C21.3,5.7,21.3,5.2,21.1,4.8z"/>
          <path style="fill:none;" d="M19,6.9c0.8,0.3,1.7,0,2.1-0.8c0.2-0.4,0.2-0.9,0-1.3c0,0,0,0,0,0l0,0c-0.2-0.3-0.4-0.6-0.8-0.8
				c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0.2,0,0.3-0.1,0.5c0,0,0,0.1-0.1,0.1C19.7,5,19.3,5.2,18.8,5c-0.2-0.1-0.4-0.2-0.5-0.4
				c-0.1,0.1-0.1,0.2-0.2,0.3C17.9,5.6,18.2,6.6,19,6.9z"/>

          <linearGradient id="SVGID_00000088820583645276896440000013045314085888901522_" gradientUnits="userSpaceOnUse" x1="7.2721" y1="14.5171" x2="10.3303" y2="14.5171" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_00000088820583645276896440000013045314085888901522_);" d="M10.3,9.4C10.3,9.4,10.3,9.4,10.3,9.4
				c-0.8,4.3-1.2,4.9-3.1,6.2l0.6,0.6c0.4-0.4,1-1.1,1.6-2.3C10.2,12.6,10.3,10.5,10.3,9.4z"/>
          <path style="fill:none;" d="M21.1,4.8L21.1,4.8c-0.2-0.3-0.4-0.6-0.8-0.8c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0.2,0,0.3-0.1,0.5
				c0,0,0,0.1-0.1,0.1C19.7,5,19.3,5.2,18.8,5c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.8,0,1.7,0.8,2.1
				c0.8,0.3,1.7,0,2.1-0.8C21.3,5.7,21.3,5.2,21.1,4.8L21.1,4.8z"/>
          <path style="fill:none;" d="M19.5,11.3C19.6,11.2,19.6,11.2,19.5,11.3C19.6,11.2,19.6,11.2,19.5,11.3c-0.4,0.3-0.8,0.7-1.1,1.2
				C18.8,12,19.1,11.6,19.5,11.3z"/>

          <linearGradient id="SVGID_00000140719210844183836010000015603961068336256142_" gradientUnits="userSpaceOnUse" x1="-34.5933" y1="-153.7763" x2="-0.2167" y2="-46.1626" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_00000140719210844183836010000015603961068336256142_);" d="M19.6,11.2
				C19.6,11.2,19.6,11.3,19.6,11.2c-0.4,0.4-0.8,0.8-1.2,1.3c-1,1.5-1.6,3.7-1.2,6.6l0,0c0,0,0,0,0,0l1.5-1.5
				c-0.1-0.2-0.1-0.5-0.2-0.7c-0.5-2.2-0.1-3.7,1.2-5.3c0.2-0.2,0.6-0.6,0.9-1c0,0,0,0,0,0C20.3,10.7,19.9,10.9,19.6,11.2z"/>
          <path style="fill:#FFFFFF;" d="M18.4,12.5c-1,1.5-1.6,3.7-1.2,6.6l0,0C16.8,16.2,17.4,14,18.4,12.5z"/>

          <linearGradient id="SVGID_00000164488257289818299050000006261024507097267859_" gradientUnits="userSpaceOnUse" x1="-35.069" y1="-153.6244" x2="-0.6923" y2="-46.0106" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
            <stop offset="0" style="stop-color:#5D47FF"/>
            <stop offset="0.5903" style="stop-color:#846DFF"/>
            <stop offset="1" style="stop-color:#A36CFF"/>
          </linearGradient>
          <path style="fill:url(#SVGID_00000164488257289818299050000006261024507097267859_);" d="M18.4,12.5c-1,1.5-1.6,3.7-1.2,6.6l0,0
				C16.8,16.2,17.4,14,18.4,12.5z"/>
          <g>

            <linearGradient id="SVGID_00000171000423682840577590000006236199854572186037_" gradientUnits="userSpaceOnUse" x1="18.7973" y1="21.3449" x2="26.1245" y2="21.3449" gradientTransform="matrix(1 0 0 -1 0 27.2999)">
              <stop offset="0" style="stop-color:#5D47FF"/>
              <stop offset="0.5903" style="stop-color:#846DFF"/>
              <stop offset="1" style="stop-color:#A36CFF"/>
            </linearGradient>
            <path style="fill:url(#SVGID_00000171000423682840577590000006236199854572186037_);" d="M25.9,6.5c-0.9-1.3-3.6-1.9-3.6-1.9
					s-0.4-1-1.3-1.3c-0.9-0.3-2.2,0.2-2.2,0.2l0,0c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.1,0,0.2,0c0.9,0.1,1.7,0.7,1.9,1.6
					c4.6,1.1,4.5,2.5,3.1,3.6c-0.1,0-0.1,0.1-0.2,0.1l0,0C24.7,8.7,26.8,7.7,25.9,6.5z"/>
            <path style="fill:#FFFFFF;" d="M21.7,5c-0.2-0.9-1-1.5-1.9-1.6c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.1-0.8,0.2
					c-0.4,0.2-0.8,0.5-1,0.9c-2.8,0-5.8,1.1-7.1,4c-0.1,0.3-0.2,0.6-0.4,0.9c0,1.2-0.1,3.2-0.8,4.6c-0.6,1.1-1.2,1.8-1.6,2.3
					l1.9,1.9l3.9,3.9l0,0c0.1,0.1,0.3,0.1,0.4,0l0.4-0.4l2.6-2.6c-0.4-2.8,0.2-5.1,1.2-6.6c0.3-0.5,0.7-0.9,1.1-1.2c0,0,0,0,0,0
					c0.4-0.3,0.7-0.5,1.1-0.6c0,0,0,0,0,0c0,0,0.1,0,0.1,0c1.1-0.4,2.8-1,3.8-1.8c0.1,0,0.1-0.1,0.2-0.1C26.2,7.5,26.3,6.1,21.7,5z
					 M21.1,6.1c-0.3,0.8-1.3,1.2-2.1,0.8c-0.8-0.3-1.2-1.3-0.8-2.1c0-0.1,0.1-0.2,0.2-0.3c0.1,0.2,0.3,0.3,0.5,0.4
					c0.4,0.2,0.9,0,1.2-0.4c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0.1-0.2,0.1-0.3,0.1-0.5c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0
					c0.4,0.2,0.6,0.4,0.8,0.8l0,0c0,0,0,0,0,0c0.1,0.3,0.2,0.5,0.1,0.8C21.2,5.8,21.2,5.9,21.1,6.1z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
