<style lang="scss">
  @import "@/assets/scss/info-box";
</style>

<script setup>
import TonLogo from '@/components/TonLogo.vue';
import TonScanLogo from '@/components/TonScanLogo.vue';
import StoneFiLogo from '@/components/StoneFiLogo.vue';
import DeDustLogo from '@/components/DeDustLogo.vue';
import GeckoLogo from '@/components/GeckoLogo.vue';
const CA = 'EQCCrXl1HLQpfONe_QI5eA_SAZqjYS7gDkmWRH9FX7ZxXY5w'


const onCopyClick = async (address) => {
  const clipboard = navigator.clipboard;
  if (clipboard) {
    await clipboard.writeText(address);
  }
}

</script>

<template>
  <div class="info-box">
    <section class="info-box__yos-pic"></section>
    <aside class="info-box__aside">
      <header class="info-box__about">
        <blockquote class="info-box__desc">
          <h1 class="info-box__title">Do you know
            <b class="info-box__title-name">YOSEF</b>?
          </h1>
          Yosef, the most soulful exotic shorthair cat in the world.
        </blockquote>
        <div class="info-box__header-wrap">
          <h2 class="info-box__sub-title">And today! Finally!</h2>
        </div>
        <p class="info-box__par">His soul was tokenized via
          <em class="info-box__ticker">$YOS</em>
          meme coin, on a
          <TonLogo />
        </p>
      </header>
      <div class="info-box__data-wrap">
        <ul class="info-box__list">
          <li class="info-box__list-item">
            <dfn class="info-box__data-header">Total:</dfn>
            <ins class="info-box__data-value info-box__data-value_colorized">1,100,000</ins>
          </li>
          <li class="info-box__list-item">
            <dfn class="info-box__data-header">Address:</dfn>
            <ins class="info-box__data-value info-box__data-value_colorized info-box__data-value_ca">
              <span class="info-box__ca">{{CA}}</span>
              <svg
                @click="onCopyClick(CA)"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="info-box__copy-link"
              >
                <path d="M20,6V17a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V3A1,1,0,0,1,9,2h7a1.05,1.05,0,0,1,.71.29l3,3A1,1,0,0,1,20,6ZM17,21a1,1,0,0,0-1-1H6V6A1,1,0,0,0,4,6V20a2,2,0,0,0,2,2H16A1,1,0,0,0,17,21Z"></path>
              </svg>
            </ins>
          </li>
          <li class="info-box__list-item">
            <dfn class="info-box__data-header info-box__data-header_tg">Details:</dfn>
              <a
                href="https://tonscan.org/jetton/EQCCrXl1HLQpfONe_QI5eA_SAZqjYS7gDkmWRH9FX7ZxXY5w"
                target="_blank"
                class="info-box__tonscan-link"
                title="view details on explorer"
              >
                <TonScanLogo />
              </a>
          </li>
          <li class="info-box__list-item">
            <dfn class="info-box__data-header info-box__data-header_tg">Stats:</dfn>
            <a
              href="https://www.geckoterminal.com/ton/pools/EQBvzs-6Gww695t67U3e2mCNgszK1GhISliW12L2QEzoP4hN"
              target="_blank"
              class="info-box__gecko-terminal"
              title="view stats on geckoterminal.com"
            >
              <GeckoLogo />
            </a>
          </li>
          <li class="info-box__list-item info-box__list-item_pools">
            <dfn class="info-box__data-header">Buy it on:</dfn>
            <aside class="info-box__aside-pools">
              <a
                href="https://app.ston.fi/pools/EQDlEMT4bNGVe7HZooQDZqidofTr8KZ2aAH6whXhAifgNSno"
                target="_blank"
                class="info-box__link"
                title="buy it on Stone.fi dex"
              >
                <StoneFiLogo />
              </a>
              <a
                href="https://dedust.io/pools/EQBvzs-6Gww695t67U3e2mCNgszK1GhISliW12L2QEzoP4hN"
                target="_blank"
                class="info-box__link"
                title="buy it on DeDust dex"
              >
                <DeDustLogo />
              </a>
            </aside>
          </li>
          <li class="info-box__list-item">
            <dfn class="info-box__data-header info-box__data-header_tg">
              Tg
              <svg
                fill="#fff"
                width="32px"
                height="32px"
                viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M228.646,34.7676a11.96514,11.96514,0,0,0-12.21778-2.0752L31.87109,105.19729a11.99915,11.99915,0,0,0,2.03467,22.93457L84,138.15139v61.833a11.8137,11.8137,0,0,0,7.40771,11.08593,12.17148,12.17148,0,0,0,4.66846.94434,11.83219,11.83219,0,0,0,8.40918-3.5459l28.59619-28.59619L175.2749,217.003a11.89844,11.89844,0,0,0,7.88819,3.00195,12.112,12.112,0,0,0,3.72265-.59082,11.89762,11.89762,0,0,0,8.01319-8.73925L232.5127,46.542A11.97177,11.97177,0,0,0,228.646,34.7676ZM32.2749,116.71877a3.86572,3.86572,0,0,1,2.522-4.07617L203.97217,46.18044,87.07227,130.60769,35.47461,120.28811A3.86618,3.86618,0,0,1,32.2749,116.71877Zm66.55322,86.09375A3.99976,3.99976,0,0,1,92,199.9844V143.72048l35.064,30.85669ZM224.71484,44.7549,187.10107,208.88772a4.0003,4.0003,0,0,1-6.5415,2.10937l-86.1543-75.8164,129.66309-93.645A3.80732,3.80732,0,0,1,224.71484,44.7549Z"/>
              </svg>:</dfn>
            <a
              href="https://t.me/yosTokenized"
              target="_blank"
              class="info-box__tg-link"
            >t.me/yosTokenized</a>
          </li>
        </ul>
      </div>

    </aside>
  </div>
</template>
